import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import "./Request.scss";

import { Box, Button, Card, CardContent, Grid } from "@mui/material";

import { RequestCreate, RequestResults, IntroText } from "@ubiops/widgets";

import { Loader } from "components/atoms";
import { PageTitle } from "components/molecules";
import { MainLayout, PageContainer } from "components/organisms";
import { Home } from "components/pages";

import { signOut, signIn } from "redux/actions/auth";
import { getDeployment, getDeploymentReset } from "redux/actions/deployments";
import { getPipeline, getPipelineReset } from "redux/actions/pipelines";
import {
  getBlob,
  getBlobReset,
  createBlob,
  createBlobReset,
} from "redux/actions/blobs";

import { api } from "utilities/api-helper";
import {
  TIME_OUT_FILES,
  TIME_OUT_REQUESTS,
  TIME_OUT_PIPELINE_REQUESTS,
  ENV_INTERNAL_TOKEN,
  ENV_INTERNAL_PROJECT,
} from "utilities/constants";
import { createErrorNotification } from "utilities/notifications";
import { LOADED } from "utilities/request-statuses";
import { formatRequestData } from "utilities/input-parser";
import { useQuery } from "hooks";
import env from "services/EnvironmentManager";
import useCreateFile from "./useCreateFile";
import useDownloadFile from "./useDownloadFile";
import useDeleteFile from "./useDeleteFile";

const REQUEST_KEY = "1";

const RequestHOC = (props) => {
  const { token, signIn, signOut } = props;

  const query = useQuery();
  useEffect(() => {
    if (query.get("internal_iframe")) {
      signIn({
        token: env.get(ENV_INTERNAL_TOKEN),
        project: env.get(ENV_INTERNAL_PROJECT),
      });
    }
  }, [signIn, query]);

  if (!token) {
    return <Home />;
  } else if (query.get("internal_iframe")) {
    return <Request {...props} />;
  } else {
    return (
      <MainLayout>
        <PageContainer>
          <Grid container alignItems="center" marginLeft="-12px">
            <Grid item>
              <Button className="link" onClick={signOut}>
                Token
              </Button>
            </Grid>
            <Grid item>{">"}</Grid>
            <Grid item>
              <Button className="link">Request</Button>
            </Grid>
          </Grid>
          <PageTitle title="Create request" />
          <Request {...props} />
        </PageContainer>
      </MainLayout>
    );
  }
};

const Request = ({
  deployment,
  getDeployment,
  getDeploymentStatus,
  getDeploymentReset,
  pipeline,
  getPipeline,
  getPipelineStatus,
  getPipelineReset,
  getBlob,
  getBlobStatus,
  getBlobReset,
  createBlob,
  createBlobReset,
  createBlobStatus,
  currentBlobs,
  downloadPreviews,
  uploadProgress,
  token,
}) => {
  const {
    projectName,
    pipelineName,
    deploymentName,
    versionName,
  } = useParams();

  const [results, setResults] = useState();
  const [loading, setLoading] = useState(false);
  const request = pipeline || deployment;
  const { createFile, currentFiles, createFileStatus } = useCreateFile(token, request);
  const { downloadFile } = useDownloadFile(token);
  const { deleteFile } = useDeleteFile(token, request);
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      if (deploymentName) {
        getDeployment(deploymentName);
      } else if (pipelineName) {
        getPipeline(pipelineName);
      }
    }

    return () => {
      setLoading(false);
      setResults();
      getDeploymentReset();
      getPipelineReset();
    };
  }, [
    token,
    projectName,
    deploymentName,
    getDeployment,
    getDeploymentReset,
    pipelineName,
    getPipeline,
    getPipelineReset,
  ]);

  const createRequest = (
    url,
    request,
    inputType,
    inputFields,
    timeoutParam
  ) => {
    setLoading(true);
    url = versionName
      ? `${url}/versions/${versionName}/requests`
      : `${url}/requests?${timeoutParam}`;

    const data = formatRequestData(inputFields, inputType, request);
    api(
      {
        method: "POST",
        url,
        data: data,
        timeout: TIME_OUT_FILES,
      },
      token
    )
      .then((response) => {
        setResults(response.data);
      })
      .catch((error) => {
        if (error?.response?.data) {
          setResults(error.response.data);
        } else {
          dispatch(createErrorNotification(error.message || error.msg));
        }
      })
      .finally(() => setLoading(false));
  };

  const createDeploymentRequest = ({ requests }) => {
    createRequest(
      `/projects/${projectName}/deployments/${deploymentName}`,
      requests?.[REQUEST_KEY],
      deployment.input_type,
      deployment.input_fields,
      `timeout=${TIME_OUT_REQUESTS}`
    );
  };

  const createPipelineRequest = ({ requests }) => {
    createRequest(
      `/projects/${projectName}/pipelines/${pipelineName}`,
      requests?.[REQUEST_KEY],
      pipeline.input_type,
      pipeline.input_fields,
      `pipeline_timeout=${TIME_OUT_PIPELINE_REQUESTS}&deployment_timeout=${TIME_OUT_REQUESTS}`
    );
  };

  const isLoading = useMemo(
    () =>
      (!!deploymentName && getDeploymentStatus === LOADED) ||
      (!!pipelineName && getPipelineStatus === LOADED),
    [deploymentName, getDeploymentStatus, pipelineName, getPipelineStatus]
  );

  const obj = useMemo(
    () => (!!deploymentName && !!deployment ? deployment : pipeline),
    [deploymentName, deployment, pipeline]
  );

  return isLoading ? (
    <Grid container direction="column">
      <Grid item>
        <Box className="request__section">
          <IntroText />
          {!!obj && (
            <>
              <RequestCreate
                name={obj.name}
                description={obj.description}
                version={versionName}
                inputType={obj.input_type}
                inputFields={obj.input_fields}
                loading={loading}
                createRequest={
                  !!deploymentName && !!deployment
                    ? createDeploymentRequest
                    : createPipelineRequest
                }
                createBlob={createBlob}
                createBlobReset={createBlobReset}
                createBlobStatus={createBlobStatus}
                currentBlobs={currentBlobs}
                uploadProgress={uploadProgress}
                createFile={createFile}
                createFileStatus={createFileStatus}
                currentFiles={currentFiles}
                onDelete={deleteFile}
              />
              <RequestResults
                inputFields={obj.input_fields}
                outputFields={obj.output_fields}
                loading={loading}
                results={results}
                getBlob={downloadFile}
                getBlobStatus={getBlobStatus}
                getBlobReset={getBlobReset}
                downloadPreviews={downloadPreviews}
              />
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  ) : (
    <Card className="request__section" variant="outlined">
      <CardContent>
        <Loader />
      </CardContent>
    </Card>
  );
};

Request.propTypes = {
  token: PropTypes.string,
  signIn: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  deployment: PropTypes.object,
  getDeployment: PropTypes.func.isRequired,
  getDeploymentStatus: PropTypes.string.isRequired,
  getDeploymentReset: PropTypes.func.isRequired,
  pipeline: PropTypes.object,
  getPipeline: PropTypes.func.isRequired,
  getPipelineStatus: PropTypes.string.isRequired,
  getPipelineReset: PropTypes.func.isRequired,
  createBlob: PropTypes.func.isRequired,
  createBlobStatus: PropTypes.object,
  createBlobReset: PropTypes.func.isRequired,
  getBlob: PropTypes.func.isRequired,
  getBlobStatus: PropTypes.object,
  getBlobReset: PropTypes.func.isRequired,
  currentBlobs: PropTypes.object,
  downloadPreviews: PropTypes.object,
  uploadProgress: PropTypes.object,
};

const mapStateToProps = ({
  auth: { token },
  deployments: { deployment },
  pipelines: { pipeline },
  status: { getDeployment, getPipeline },
  statusWithIds: { createBlob, getBlob },
  blobs: { currentBlobs, downloadPreviews, uploadProgress },
}) => ({
  token,
  deployment,
  pipeline,
  getDeploymentStatus: getDeployment,
  getPipelineStatus: getPipeline,
  getBlobStatus: getBlob,
  createBlobStatus: createBlob,
  currentBlobs,
  downloadPreviews,
  uploadProgress,
});

const mapDispatchToProps = {
  getBlob,
  getBlobReset,
  getDeployment,
  getDeploymentReset,
  getPipeline,
  getPipelineReset,
  createBlob,
  createBlobReset,
  signOut,
  signIn,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestHOC);
