import { handleActions } from "redux-actions";
import * as types from "redux/actions/actionTypes";

const initialState = {
  notifications: [],
};

const appReducer = handleActions(
  {
    [types.ENQUEUE_SNACKBAR]: (state, action) => ({
      ...state,
      notifications: [
        ...state.notifications,
        {
          key: action.key,
          ...action.notification,
        },
      ],
    }),
    [types.REMOVE_SNACKBAR]: (state, action) => ({
      ...state,
      notifications: state.notifications.filter(
        (notification) => notification.key !== action.key
      ),
    }),
    [types.CLOSE_SNACKBAR]: (state, action) => ({
      ...state,
      notifications: state.notifications.map((notification) =>
        action.dismissAll || notification.key === action.key
          ? { ...notification, dismissed: true }
          : { ...notification }
      ),
    }),
  },
  initialState
);

export default appReducer;
