import {
  ARRAY_STRING_TYPE,
  ARRAY_INT_TYPE,
  ARRAY_DOUBLE_TYPE,
  STRING_TYPE,
  INT_TYPE,
  DOUBLE_TYPE,
  BOOLEAN_TYPE,
  // input type
  STRUCTURED_TYPE,
  // widgets
  ENV_FILE_PREFIX,
  ENV_DEFAULT_BUCKET_NAME,
} from "./constants";

const ARRAY_TYPES = [ARRAY_STRING_TYPE, ARRAY_INT_TYPE, ARRAY_DOUBLE_TYPE];

export const FIELD_EDITOR = "editorField";
export const VISUAL_EDITOR = "visualEditor";
export const JSON_EDITOR = "jsonEditor";

export const formatInput = (fields, data) => {
  let newData = {};
  fields.forEach(({ name, data_type, widget }) => {
    if (!data[name]) {
      switch (data_type) {
        case INT_TYPE:
        case DOUBLE_TYPE:
          newData[name] = null;
          break;
        case ARRAY_DOUBLE_TYPE:
        case ARRAY_INT_TYPE:
        case ARRAY_STRING_TYPE:
          newData[name] = [];
          break;
        default:
          newData[name] = "";
          break;
      }
      return;
    }

    if (ARRAY_TYPES.includes(data_type)) {
      newData[name] = toArray(data[name], data_type);
    } else {
      newData[name] = toType(data[name], data_type);
    }
  });
  return newData;
};

const toType = (s, data_type) => {
  switch (data_type) {
    case INT_TYPE:
      const intValue = parseInt(s);
      return isNaN(intValue) ? null : intValue;
    case DOUBLE_TYPE:
      const doubleValue = parseFloat(s);
      return isNaN(doubleValue) ? null : doubleValue;
    case BOOLEAN_TYPE:
      return ["TRUE", "True", "true", "1", "t", "y", "yes"].includes(
        s.toLowerCase()
      );
    case STRING_TYPE:
      if (s.startsWith('"') && s.endsWith('"')) {
        // Prevent "test" to become '"test"'
        return s.replace('"', "").replace(/"(?![\s\S]*")/, "");
      } else if (s.startsWith("'") && s.endsWith("'")) {
        // Prevent 'test' to become "'test'"
        return s.replace("'", "").replace(/'(?![\s\S]*')/, "");
      } else {
        return s.toString();
      }
    default:
      return s;
  }
};

const toArray = (s, object_type) => {
  s = toArrayString(s.trim());
  if (object_type === ARRAY_STRING_TYPE) {
    try {
      // allow ["a's", "b's"] and "a's", "b's"
      // allow [1, 2, 3] and 1, 2, 3
      return JSON.parse(s).map((x) => x.toString());
    } catch (e) {
      try {
        // allow ['a', 'b'] and 'a', 'b'
        return JSON.parse(s.replace(/'/g, '"')).map((x) => x.toString());
      } catch (e) {
        // allow [a, b] and a, b
        return stringToArray(s);
      }
    }
  } else {
    return castJsonString(s);
  }
};

const toArrayString = (s) => {
  if (s.startsWith("[") && s.endsWith("]")) {
    return s;
  } else {
    return `[${s}]`;
  }
};

const castJsonString = (s) => {
  try {
    return JSON.parse(s);
  } catch (e) {
    return stringToArray(s);
  }
};

const stringToArray = (s) => {
  // remove first [ and last ]
  s = s.replace("[", "").replace(/](?![\s\S]*])/, "");

  // split by ,
  s = s.split(",");

  // trim strings
  return s.map((x) => x.trim());
};

export const parseJsonNoErrors = (s) => {
  try {
    return JSON.parse(s);
  } catch (e) {
    return null;
  }
};

export const formatRequestData = (inputFields, inputType, request) => {
  if (inputFields && inputType === STRUCTURED_TYPE) {
    return request[FIELD_EDITOR] === JSON_EDITOR
      ? parseJsonNoErrors(request["json-area"])
      : formatInput(inputFields, request);
  } else {
    return request["plain-area"];
  }
};

export const formatInputOutputFields = (fields) => {
  const formattedFields = Object.values(fields)
    .map((inputOutput) => {
      inputOutput.data_type = inputOutput.data_type.value;
      return {
        name: inputOutput.name,
        data_type: inputOutput.data_type,
      };
    })
    .filter(({ name }) => !!name);
  return formattedFields;
};

export const formatOldInputOutputFields = (fields) => {
  return fields.map((inputOutput) => {
    return {
      name: inputOutput.name,
      data_type: inputOutput.data_type,
    };
  });
};

export const isBlobFile = (fileName) => !fileName.startsWith(ENV_FILE_PREFIX)

// example inputName: "request[1].file-arr"
export const getBucketName = (inputName, request) => {
  const fieldName = inputName?.split('.')?.[1];
  const formFieldConfig = request?.input_fields?.find(field => field.name === fieldName);
  return formFieldConfig?.widget?.configuration?.bucket || ENV_DEFAULT_BUCKET_NAME;
}

// example fileName: "ubiops://bucketName/path/to/file"
export const getBucketNameFromFileName = (fileName) => {
  const path = fileName.replace(ENV_FILE_PREFIX, "");
  return path?.split("/")[0] || ENV_DEFAULT_BUCKET_NAME
}
