import * as types from "redux/actions/actionTypes";
import { generateActions } from "redux/actions/utils";

const actions = {
  ...generateActions(types.BLOB_ALL, true),
  ...generateActions(types.BLOB_ONE, true, true, true),
  ...generateActions(types.BLOB_CREATE, true, true, true),
};

export default actions;

export const {
  getBlobs,
  getBlobsReset,
  getBlob,
  getBlobReset,
  createBlob,
  createBlobReset,
} = actions;
