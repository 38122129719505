import createSagaMiddleware from "redux-saga";
import rootSaga from "redux/sagas";
import { createBrowserHistory } from "history";
import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import rootReducer from './reducers';
import { filesApi } from "./reducers/filesApi";

export const history = createBrowserHistory();

const preloadedState = {};
const createStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const store = configureStore({
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware({
        immutableCheck: false, 
        serializableCheck: false,
      }),
      routerMiddleware(history),
      sagaMiddleware,
      filesApi.middleware,
    ],
    reducer: rootReducer(history),
    preloadedState,
  })
  sagaMiddleware.run(rootSaga);
  return store;
}

export default createStore;
