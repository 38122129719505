import { enqueueSnackbar } from "redux/actions/app";

export const createNotification = (
  message,
  variant = "default",
  options = {}
) => {
  return (
  enqueueSnackbar({
    message,
    options: {
      variant,
      key: new Date().getTime() + Math.random(),
      ...options,
    },
   } )
  )};

export const createDefaultNotification = (message, options = {}) =>
  createNotification(message, "default", options);

export const createSuccessNotification = (message, options = {}) =>
  createNotification(message, "success", options);

export const createInfoNotification = (message, options = {}) =>
  createNotification(message, "info", options);

export const createWarningNotification = (message, options = {}) =>
  createNotification(message, "warning", options);

export const createErrorNotification = (message, options = {}) => {
  if (!message) {
    message = "An unknown error occurred";
  }
  return createNotification(message, "error", options);
};
