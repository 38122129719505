import { createTheme } from "@mui/material/styles";

import appTheme from "./theme";
import typography from "./typography";
import breakpoints from "./breakpoints";
import props from "./props";

const theme = ({ body, header, palette, sidebar, name }) => {
  return createTheme({
    breakpoints,
    props,
    typography,
    body,
    header,
    palette,
    sidebar,
    name,
  });
};

export default theme(appTheme);
