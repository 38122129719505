import React from "react";
import PropTypes from "prop-types";
import "./InfoTooltip.scss";
import { Tooltip } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

import { Icon } from "components/atoms";
import { stopPropagation } from "utilities/utils";

const InfoTooltip = ({ children }) => (
  <Tooltip
    title={<div onClick={stopPropagation}>{children}</div>}
    placement="bottom-start"
    interactive="true"
    onClick={(e) => e.preventDefault()}
  >
    <Icon component={HelpIcon} status="none" className="info-tooltip" />
  </Tooltip>
);

InfoTooltip.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InfoTooltip;
