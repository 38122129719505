import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import "./TokenForm.scss";

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";

import { TextField } from "components/atoms";
import { ObjectForm } from "components/organisms";

import { signIn } from "redux/actions/auth";

import validators from "utilities/validators";

const FIELD_TOKEN = "token";

const TokenForm = ({ signIn, project, deployment, pipeline, version }) => {
  const history = useHistory();
  const methods = useForm({
    mode: "onBlur",
    defaultValues: {
      project,
      objectType: pipeline ? "pipeline" : "deployment",
      deployment,
      pipeline,
      version,
    },
  });
  const { handleSubmit, register } = methods;

  const onSubmit = (data) => {
    let token = data[FIELD_TOKEN].trim();
    if (data.objectType === "deployment") {
      if (data.version) {
        history.push(
          `/projects/${data.project}/deployments/${data.deployment}/versions/${data.version}`
        );
      } else {
        history.push(
          `/projects/${data.project}/deployments/${data.deployment}`
        );
      }
    } else {
      if (data.version) {
        history.push(
          `/projects/${data.project}/pipelines/${data.pipeline}/versions/${data.version}`
        );
      } else {
        history.push(`/projects/${data.project}/pipelines/${data.pipeline}`);
      }
    }
    signIn({
      project: data.project,
      token: token.startsWith("Token") ? token : `Token ${token}`,
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        Use a token to authenticate:
        <TextField
          name={FIELD_TOKEN}
          autoComplete="off"
          label="Token"
          rules={{
            required: validators.required.message(FIELD_TOKEN),
          }}
          placeholder="Token 123a4bc5def6ghi7jklmnopqrs890tuv10wx11yz"
        />
        {!project || (!deployment && !pipeline) ? (
          <ObjectForm />
        ) : (
          <Box mt={2} mb={2}>
            <Card>
              <CardContent>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <InputLabel shrink>Project</InputLabel>
                    <Typography>{project}</Typography>
                  </Grid>

                  {!!pipeline ? (
                    <Grid item>
                      <InputLabel shrink>Pipeline</InputLabel>
                      <Typography>{pipeline}</Typography>
                    </Grid>
                  ) : (
                    <Grid item>
                      <InputLabel shrink>Deployment</InputLabel>
                      <Typography>{deployment}</Typography>
                    </Grid>
                  )}
                  {!!version && (
                    <Grid item>
                      <InputLabel shrink>Version</InputLabel>
                      <Typography>{version}</Typography>
                    </Grid>
                  )}
                </Grid>
                <input type="hidden" name="project" ref={register} readOnly />
                <input
                  type="hidden"
                  name="objectType"
                  ref={register}
                  readOnly
                />
                <input
                  type="hidden"
                  name="deployment"
                  ref={register}
                  readOnly
                />
                <input type="hidden" name="pipeline" ref={register} readOnly />
                <input type="hidden" name="version" ref={register} readOnly />
              </CardContent>
            </Card>
          </Box>
        )}
        <Grid container alignItems="center" direction="column">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="tokenform__button"
            >
              Connect
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

TokenForm.propTypes = {
  signIn: PropTypes.func.isRequired,
  project: PropTypes.string,
  deployment: PropTypes.string,
  pipeline: PropTypes.string,
  version: PropTypes.string,
};

const mapDispatchToProps = {
  signIn,
};

export default connect(null, mapDispatchToProps)(TokenForm);
