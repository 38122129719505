export const white = "#ffffff";
export const black = "#000000";
export const primary = "#263548";
export const primaryLight = "#232f3e";
export const secondary = "#f2682a";
export const lightGrey = "#727b91";
export const lightPurple = "#b1acc4";
export const sidebar = "#f9f9fc";
export const background = "#ffffff";
export const error = "#d62728";
export const success = "#69b764";
export const blue = "#2196f3";
export const initialised = "#729ece";
export const pending = "#ff7f0e";
export const link = "#0273ff";
export const hover = "#dedede";
export const update = "#e9a208";
export const lightgreen = "#7eb26d";
