import React from "react";

export const BaseUrlContext = React.createContext("");
export const RootUrlContext = React.createContext("");

export const LoaderContextGlobal = React.createContext("");

export const LoaderContext = ({ children, ...methods }) => (
  <LoaderContextGlobal.Provider value={{ ...methods }}>
    {children}
  </LoaderContextGlobal.Provider>
);

export const DialogContextGlobal = React.createContext("");

export const DialogContext = ({ children, ...methods }) => (
  <DialogContextGlobal.Provider value={{ ...methods }}>
    {children}
  </DialogContextGlobal.Provider>
);
