import { handleActions } from "redux-actions";

import { UNLOADED, LOADED } from "utilities/request-statuses";
import * as types from "redux/actions/actionTypes";

// Initial State
const initialState = {
  status: UNLOADED,
  token: null,
  project: null,
};

// Reducer
const authReducer = handleActions(
  {
    [types.SIGN_IN]: (state, action) => ({
      ...state,
      status: LOADED,
      token: action.token,
      project: action.project,
    }),
    [types.SIGN_OUT]: (state) => ({
      ...state,
      status: UNLOADED,
      token: null,
      project: null,
    }),
  },
  initialState
);

export default authReducer;
