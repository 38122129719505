import React, { useRef, useState } from "react";
import "./DropdownMenu.scss";
import PropTypes from "prop-types";

import {
  IconButton,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { NavLink } from "components/atoms";

const DropdownMenu = ({
  icon,
  items,
  size = "medium",
  tooltip = "",
  ...props
}) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={tooltip} arrow>
        <IconButton
          ref={anchorRef}
          onClick={handleToggle}
          color="inherit"
          aria-controls="dropdown-menu"
          aria-haspopup="true"
          size={size}
          {...props}
        >
          {icon}
        </IconButton>
      </Tooltip>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} className="dropdown-menu__content">
            <Paper elevation={2}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="dropdown-menu" onClick={handleClose}>
                  {items.map(
                    ({
                      label,
                      path,
                      url,
                      divider = false,
                      hidden = false,
                      onClick,
                    }) =>
                      !hidden && (
                        <MenuItem
                          component={path ? NavLink : url ? "a" : "li"}
                          to={path}
                          href={url}
                          target={url && "_blank"}
                          key={label}
                          divider={divider}
                          disabled={!path && !url && !onClick}
                          onClick={onClick}
                        >
                          {label}
                        </MenuItem>
                      )
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

DropdownMenu.propTypes = {
  icon: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      path: PropTypes.string,
      url: PropTypes.string,
      divider: PropTypes.bool,
      hidden: PropTypes.bool,
      onClick: PropTypes.func,
    })
  ).isRequired,
  size: PropTypes.oneOf(["medium", "small"]),
  tooltip: PropTypes.string,
};

export default DropdownMenu;
