import {
  INT_TYPE,
  DOUBLE_TYPE,
  BOOLEAN_TYPE,
  STRING_TYPE,
  BLOB_TYPE,
  ARRAY_STRING_TYPE,
  ARRAY_INT_TYPE,
  ARRAY_DOUBLE_TYPE,
} from "./constants";

const validateJSON = (value) => {
  try {
    JSON.parse(value);
    return true;
  } catch (e) {
    return "Invalid JSON format";
  }
};

const validators = {
  name: {
    pattern: /^[a-z0-9][a-z0-9-]*[a-z0-9]$/,
    message: (field = "field") =>
      `The ${field} can only consist of lower case letters, numbers and dashes(-) and should at least be 2 characters.`,
    value: (resource = "resource") => (value) =>
      value !== "create" ||
      `Calling your ${resource} "create" is not recommended and will lead to conflicts with our tools. If you still want to proceed, you can do so from another UbiOps tool (Swagger, CLI, client library...).`,
  },
  fieldName: {
    pattern: /^[a-z0-9][a-z0-9-_]*[a-z0-9]$/,
    message: (field = "field") =>
      `The ${field} can only consist of lower case letters, numbers, dashes(-) and underscores(_) and should at least be 2 characters. It cannot start or end with a _ or -.`,
  },
  labelKey: {
    pattern: /^(?![-.])([a-zA-Z0-9-_.]+)[^-_.\s]$/,
    message:
      "The label key can only consist of letters, numbers, dashes(-), underscores(_) and dots(.) and should at least be 1 character. It cannot start with a - or ., or end with a _, - or .",
  },
  labelValue: {
    pattern: /^(?![-_.])([a-zA-Z0-9-_.]+)[^-_.\s]$/,
    message:
      "The label value can only consist of letters, numbers, dashes(-), underscores(_) and dots(.) and should at least be 1 character. It cannot start or end with a _, - or .",
  },
  uniqueKeys: {
    message: "The label with the same key already exists.",
  },
  domain: {
    pattern: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, // eslint-disable-line
    message: "Please enter a valid domain.",
  },
  notFound: {
    message: (field = "field") =>
      `No ${field} found. Please create a ${field} first.`,
  },
  fields: {
    message: (field = "field") =>
      `The ${field} should not be empty for structured type!`,
  },
  required: {
    message: (field = "field") => `The ${field} is required.`,
  },
  email: {
    pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    message: "Invalid email address",
  },
  configuration: {
    message: (field = "fields", regex) => `The ${field} is invalid (${regex}).`,
  },
  json: {
    validate: (value) => validateJSON(value),
  },
  password_requirements: {
    minLength: 8,
    message: `The password should contain at least 8 characters.`,
  },
  password_confirmation: {
    message: "The password confirmation should match the password.",
  },
  positive_number: {
    message: "This value must be greater than or equal to 0.",
  },
  minimum_timeout: {
    message: "Minimum allowed value for timeout is 10 (seconds)",
  },
  maximum_timeout: {
    message: (timeout) =>
      `Maximum allowed value for timeout is ${timeout} (seconds)`,
  },
  minimum_memory: {
    message: "Memory allocation must be greater than or equal to 256",
  },
  maximum_memory: {
    message: "Memory allocation must be less than or equal to 1048576",
  },
  minimum_retention: {
    message: "Retention time must be more than or equal to 0",
  },
  maximum_retention: {
    message: "Retention time must be less than or equal to 2419200",
  },
  minimum_object_required: {
    message: (object = "object") =>
      `You must create at least one ${object} to create a request schedule`,
  },
  [INT_TYPE]: {
    validate: (value) =>
      value === "null" ||
      !isNaN(parseInt(value)) ||
      "This value must be an integer.",
  },
  [DOUBLE_TYPE]: {
    validate: (value) =>
      value === "null" ||
      !isNaN(parseFloat(value)) ||
      "This value must be a double.",
  },
  [BOOLEAN_TYPE]: {
    validate: (value) =>
      [
        "TRUE",
        "FALSE",
        "True",
        "False",
        "true",
        "false",
        "1",
        "0",
        "t",
        "f",
        "y",
        "n",
        "yes",
        "no",
      ].includes(value) || "This value must be a boolean.",
  },
  [STRING_TYPE]: {
    validate: (value) => true,
  },
  [BLOB_TYPE]: {
    validate: (value) =>
      value.match(/^[a-z0-9-]+$/) || "You must provide a blob.",
  },
  [ARRAY_STRING_TYPE]: {
    validate: (value) => true,
  },
  [ARRAY_INT_TYPE]: {
    validate: (value) => true,
  },
  [ARRAY_DOUBLE_TYPE]: {
    validate: (value) => true,
  },
};
export default validators;
