import { put, take } from "redux-saga/effects";
import { eventChannel, END } from "redux-saga";

import { apiCall } from "utilities/api-helper";

export const createLoader = (args, event, taskId = null, token) => {
  let emit;
  let emitPercentage = -1;

  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => {};
  });

  const onProgress = ({ total, loaded }) => {
    const percentage = Math.round((loaded * 100) / total);

    if (percentage === 100) {
      emit(END);
      return;
    }

    if (emitPercentage !== percentage) {
      emitPercentage = percentage;
      emit(percentage);
    }
  };

  const promise = apiCall(
    {
      ...args,
      [`on${event}Progress`]: onProgress,
    },
    token
  );

  return [promise, chan];
};

export function* progressWatcher(action, chan, urlId = null, trackName = null) {
  while (true) {
    // eslint-disable-line no-constant-condition
    const progress = yield take(chan);
    yield put(action(progress, urlId, null, trackName));
  }
}
