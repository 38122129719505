import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import {
  ENV_NAME,
  ENV_HOST,
  PROXY_HOST,
  ENV_INTERNAL_TOKEN,
  ENV_INTERNAL_PROJECT,
} from "utilities/constants";

const ENVIRONMENTS = [
  {
    [ENV_NAME]: window._env_?.WEBAPP_ENV,
    [ENV_HOST]: window._env_?.WEBAPP_API_HOST,
    [PROXY_HOST]: window._env_?.FILES_PROXY_HOST,
    [ENV_INTERNAL_TOKEN]: window._env_?.WEBAPP_INTERNAL_TOKEN,
    [ENV_INTERNAL_PROJECT]: window._env_?.WEBAPP_INTERNAL_PROJECT,
  },
];

const DEFAULT_LOCALSTORAGE_KEY = "UBIOPS_ENVIRONMENT_MANAGER";
const DEFAULTS = {
  current: window._env_?.WEBAPP_ENV,
  environments: [],
};

const HTTP_REGEX = /^https?:\/\//;
const isHttpUrl = (str) => HTTP_REGEX.test(str);
const isNonEmptyString = (str) => typeof str === "string" && str.length > 0;

class EnvironmentManager {
  constructor(key = DEFAULT_LOCALSTORAGE_KEY) {
    this.db = low(new LocalStorage(key));

    this.db.defaults(DEFAULTS).write();

    this.db
      .set(
        "environments",
        this.db
          .get("environments")
          .unionBy(ENVIRONMENTS, "name")
          .value()
      )
      .write();

    ENVIRONMENTS.forEach((env) => this.hydrateEnvironment(env.name, env));

    if (!this.current()) {
      console.error("Invalid EnvironmentManager State", this.db.value()); // eslint-disable-line no-console
    }
  }

  __environments() {
    return this.db.get("environments");
  }

  __current() {
    return this.db
      .get("environments")
      .find({ name: this.db.get("current").value() });
  }

  environments() {
    return this.__environments().value();
  }

  current() {
    return this.__current().value();
  }

  set(key, value) {
    if (key === "name") {
      throw new Error("Setting Current Environment Name Not Allowed");
    }
    this.__current()
      .set(key, value)
      .write();
  }

  unset(key) {
    if (key === "name") {
      throw new Error("Unsetting Current Environment Name Not Allowed");
    }
    this.__current()
      .set(key, null)
      .write();
  }

  get(key) {
    return this.__current()
      .get(key)
      .value();
  }

  isValidEnvironment(env) {
    const lo = this.db._;
    return (
      lo.isPlainObject(env) && isNonEmptyString(env.name) && isHttpUrl(env.host)
    );
  }

  createEnvironment(newEnv) {
    if (!this.isValidEnvironment(newEnv)) {
      throw new Error("Invalid Environment Object");
    }

    if (this.hasEnvironment(newEnv.name)) {
      throw new Error("Environment Already Exists");
    }

    this.__environments()
      .push(newEnv)
      .write();

    return newEnv;
  }

  removeEnvironment(name) {
    if (name === this.name()) {
      throw new Error("Cannot Remove Current Environment");
    }
    this.__environments()
      .remove({ name })
      .write();
  }

  getEnvironment(name) {
    return this.__environments()
      .find({ name })
      .value();
  }

  hasEnvironment(name) {
    return !!this.getEnvironment(name);
  }

  hydrateEnvironment(name, data = {}) {
    if (data.name !== name && name === this.name()) {
      throw new Error("Cannot Update Current Environment Name");
    }
    this.__environments()
      .find({ name })
      .assign(data)
      .write();
  }

  setEnvironmentAsCurrent(key) {
    if (!this.hasEnvironment(key)) {
      throw new Error("Environment Not Found");
    }
    this.db.set("current", key).write();
  }

  dump() {
    console.log("EnvironmentManager", this.db.value()); // eslint-disable-line no-console
  }
}

const EnvironmentManagerInstance = new EnvironmentManager();

export default EnvironmentManagerInstance;
