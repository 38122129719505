import React from "react";
import PropTypes from "prop-types";
import "./Radio.scss";

import { FormControl } from "@mui/material";

import { InfoTooltip } from "components/atoms";

const Radio = ({ label, tooltip, id, register, disabled, ...props }) => (
  <FormControl className="radio">
    <label
      className={["radio__label", disabled && "radio__label--disabled"]
        .filter((x) => x)
        .join(" ")}
      htmlFor={id}
    >
      <input
        id={id}
        type="radio"
        className="radio__input"
        ref={register}
        disabled={disabled}
        {...props}
      />
      <span className="radio__wrapper">
        <span className="radio__input-wrapper">
          <div className="radio__custom-input">
            <svg
              className="MuiSvgIcon-root"
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
              role="presentation"
            >
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
            </svg>
            <svg
              className="MuiSvgIcon-root PrivateRadioButtonIcon"
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
              role="presentation"
            >
              <path d="M8.465 8.465C9.37 7.56 10.62 7 12 7C14.76 7 17 9.24 17 12C17 13.38 16.44 14.63 15.535 15.535C14.63 16.44 13.38 17 12 17C9.24 17 7 14.76 7 12C7 10.62 7.56 9.37 8.465 8.465Z"></path>
            </svg>
          </div>
        </span>
      </span>

      <span>{label}</span>
      {tooltip && <InfoTooltip>{tooltip}</InfoTooltip>}
    </label>
  </FormControl>
);

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
};

export default Radio;
