import _ from "lodash";

import {
  REQUEST_POSTFIX,
  SUCCESS_POSTFIX,
  FAILURE_POSTFIX,
  RESET_POSTFIX,
} from "redux/actions/actionTypes";
import { LOADING, LOADED, ERROR, UNLOADED } from "utilities/request-statuses";
import * as actionTypes from "redux/actions/actionTypes";

const removeRequestRegex = new RegExp(REQUEST_POSTFIX + "$");
const removeSuccessRegex = new RegExp(SUCCESS_POSTFIX + "$");
const removeFailureRegex = new RegExp(FAILURE_POSTFIX + "$");
const removeResetRegex = new RegExp(RESET_POSTFIX + "$");

const initialiseState = () => {
  const state = {};
  _.forEach(actionTypes, (action) => {
    state[action] = UNLOADED;
  });
  return state;
};

const status = (state = initialiseState(), action) => {
  if (action.type.endsWith(REQUEST_POSTFIX)) {
    // Loading requests
    const actionStem = action.type.replace(removeRequestRegex, "");
    const newState = Object.assign({}, state);
    newState[actionStem] = LOADING;
    return newState;
  } else if (action.type.endsWith(SUCCESS_POSTFIX)) {
    // Successful requests
    const actionStem = action.type.replace(removeSuccessRegex, "");
    const newState = Object.assign({}, state);
    newState[actionStem] = LOADED;
    return newState;
  } else if (action.type.endsWith(FAILURE_POSTFIX)) {
    // Failed requests
    const actionStem = action.type.replace(removeFailureRegex, "");
    const newState = Object.assign({}, state);
    newState[actionStem] = ERROR;
    return newState;
  } else if (action.type.endsWith(RESET_POSTFIX)) {
    // Reset requests
    const actionStem = action.type.replace(removeResetRegex, "");
    const newState = Object.assign({}, state);
    newState[actionStem] = UNLOADED;
    return newState;
  }
  return state;
};

const statusWithIds = (state = {}, action) => {
  if (!action.trackId) {
    return state;
  }
  if (action.type.endsWith(REQUEST_POSTFIX)) {
    // Loading requests
    const actionStem = action.type.replace(removeRequestRegex, "");
    const newState = Object.assign({}, state);
    newState[actionStem] = Object.assign({}, newState[actionStem]);
    newState[actionStem][action.urlId ?? action.trackName] = LOADING;
    return newState;
  } else if (action.type.endsWith(SUCCESS_POSTFIX)) {
    // Successful requests
    const actionStem = action.type.replace(removeSuccessRegex, "");
    const newState = Object.assign({}, state);
    newState[actionStem] = Object.assign({}, newState[actionStem]);
    newState[actionStem][action.urlId ?? action.trackName] = LOADED;
    return newState;
  } else if (action.type.endsWith(FAILURE_POSTFIX)) {
    // Failed requests
    const actionStem = action.type.replace(removeFailureRegex, "");
    const newState = Object.assign({}, state);
    newState[actionStem] = Object.assign({}, newState[actionStem]);
    newState[actionStem][action.urlId ?? action.trackName] = ERROR;
    return newState;
  } else if (action.type.endsWith(RESET_POSTFIX)) {
    // Reset requests
    const actionStem = action.type.replace(removeResetRegex, "");
    const newState = Object.assign({}, state);
    newState[actionStem] = Object.assign({}, newState[actionStem]);
    newState[actionStem][action.urlId ?? action.trackName] = UNLOADED;
    return newState;
  }
  return state;
};

export { status, statusWithIds };
