// Environment
export const ENV_NAME = "name";
export const ENV_HOST = "host";
export const PROXY_HOST = "proxy_host";
export const ENV_INTERNAL_TOKEN = "internal_token";
export const ENV_INTERNAL_PROJECT = "internal_project";
export const ENV_DEFAULT_BUCKET = "ubiops-file://default/";
export const ENV_FILE_PREFIX = "ubiops-file://";
export const ENV_DEFAULT_BUCKET_NAME= "default";


// Time out
export const TIME_OUT_FILES = 3600000; // 1 hour
export const TIME_OUT_REQUESTS = 3600; // 1 hour
export const TIME_OUT_PIPELINE_REQUESTS = 7200; // 2 hours

// Input/Output types
export const STRUCTURED_TYPE = "structured";
export const PLAIN_TYPE = "plain";

// Field types
export const ARRAY_STRING_TYPE = "array_string";
export const ARRAY_INT_TYPE = "array_int";
export const ARRAY_DOUBLE_TYPE = "array_double";
export const INT_TYPE = "int";
export const DOUBLE_TYPE = "double";
export const BOOLEAN_TYPE = "bool";
export const STRING_TYPE = "string";
export const BLOB_TYPE = "blob";

// Widget Types
export const WIDGET_DROPDOWN = "dropdown";
