import React from "react";
import PropTypes from "prop-types";
import "./PageContainer.scss";

const PageContainer = ({ children, className, fullHeight = false }) => (
  <div
    className={[
      "page-container",
      fullHeight && "page-container--full-height",
      className,
    ]
      .filter((x) => x)
      .join(" ")}
  >
    <div className="page-container__content">{children}</div>
  </div>
);

PageContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  fullHeight: PropTypes.bool,
};

export default PageContainer;
