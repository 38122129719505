import * as types from "redux/actions/actionTypes";
import { generateActions } from "redux/actions/utils";

const actions = {
  ...generateActions(types.DEPLOYMENT_ONE, true),
};

export default actions;

export const { getDeployment, getDeploymentReset } = actions;
