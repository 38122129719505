import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL, PROXY_URL } from 'services/request';

const { fetch: originalFetch } = window;

const fetchFn = async (...args) => {
  let [resource, config] = args;
  // proxy service doesn't accept request headers
  if (resource.url?.startsWith(PROXY_URL)) {
    resource.headers.delete('content-type')
  }
  const response = await originalFetch(resource, config);
  return response;
};

export const filesApi = createApi({
  reducerPath: 'filesApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_URL, fetchFn }),
  endpoints: builder => ({
    createFile: builder.mutation({
      query: ({ projectName, bucketName = 'default', fileName, body, token }) => ({
        url: `/projects/${projectName}/buckets/${bucketName}/files/${fileName}`,
        headers: { Authorization: `${token}` },
        method: 'POST',
        body,
      }),
    }),
    uploadFile: builder.mutation({
      query: ({ url, body }) => ({
        url: `${PROXY_URL}/${url}`,
        method: 'PUT',
        body,
      })
    }),
    downloadFile: builder.mutation({
      query: ({ projectName, bucketName = 'default', fileName, token }) => ({
        url: `${API_URL}/projects/${projectName}/buckets/${bucketName}/files/${fileName}/download`,
        method: 'GET',
        headers: { Authorization: `${token}` },
      })
    }),
    downloadBlob: builder.mutation({
      query: ({ projectName, fileName, token }) => ({
        url: `${API_URL}/projects/${projectName}/blobs/${fileName}`,
        method: 'GET',
        headers: { Authorization: `${token}` },
        responseHandler: async (response) => {
          return {
            url: window.URL.createObjectURL(await response.blob())
          }
        },
        cache: "no-cache",
      })
    }),
    deleteFile: builder.mutation({
      query: ({ projectName, bucketName = 'default', fileName, token }) => ({
        url: `${API_URL}/projects/${projectName}/buckets/${bucketName}/files/${fileName}`,
        method: 'DELETE',
        headers: { Authorization: `${token}` },
      })
    })
  }),
});

export const {
  useCreateFileMutation,
  useUploadFileMutation,
  useDownloadFileMutation,
  useDownloadBlobMutation,
  useDeleteFileMutation
} = filesApi;
