import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const body = document.querySelector(".main-layout__body");
    if (body && body.scrollTo) {
      body.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
}
