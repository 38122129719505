import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import "./Icon.scss";
import { statusValues } from "utilities/statuses";

const Icon = forwardRef(
  (
    { component: IconComponent, status, className, disabled, ...props },
    ref
  ) => (
    <IconComponent
      className={[
        "icon",
        `icon--${status}`,
        disabled && `icon--disabled`,
        className,
      ]
        .filter((x) => x)
        .join(" ")}
      ref={ref}
      {...props}
    />
  )
);

Icon.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  status: PropTypes.oneOf(statusValues),
  className: PropTypes.string,
};

export default Icon;
