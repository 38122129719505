import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useCreateFileMutation, useUploadFileMutation } from "redux/reducers/filesApi";
import { ENV_FILE_PREFIX } from "utilities/constants";
import { getBucketName } from "utilities/input-parser";
import { createErrorNotification } from "utilities/notifications";
import { ERROR, LOADED, LOADING } from "utilities/request-statuses";

const useCreateFile = (token, request) => {
  const { projectName } = useParams();
  const [createFileMutation, createData] = useCreateFileMutation();
  const [uploadFile, uploadData] = useUploadFileMutation();
  const [createFileStatus, setCreateFileStatus] = useState({}); 
  const [currentFiles, setCurrentFiles] = useState({}); 

  const dispatch = useDispatch();

  const createFile = async (_, formData, inputName) => {
    const file = formData.get('file');
    const bucketName = getBucketName(inputName, request)
    const response = await createFileMutation({ projectName, fileName: file?.name, body: {}, token, inputName, bucketName })
    const error = response.error && response.error.data?.error
    if (error) {
      dispatch(createErrorNotification(error))
      return;
    }

    const url = response?.data?.url?.replace('https://', '');
    const uploadResponse = await uploadFile({ url, body: file })
    const uploadError = uploadResponse?.error?.error

    if (uploadError) {
      dispatch(createErrorNotification(uploadError))
    }
  }

  const isLoading = createData.isLoading || uploadData.isLoading;
  const isSuccess = createData.isSuccess && uploadData.isSuccess;
  const isError = createData.error || uploadData?.error

  useEffect(() => {
    setCreateFileStatus({
      ...createFileStatus,
      [createData?.originalArgs?.fileName]: (isLoading && LOADING) || (isSuccess && LOADED) || (isError && ERROR)
    })
    const bucketName = createData?.originalArgs?.bucketName;
    const fileName = createData?.originalArgs?.fileName;

    if (isSuccess) setCurrentFiles({
      ...currentFiles,
      [fileName]: `${ENV_FILE_PREFIX}${bucketName}/${fileName}`
    })
  // update only after request changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess, isError])

  return { createFile, currentFiles, createFileStatus }
}

export default useCreateFile;