import { put, call, select, all, takeLatest } from "redux-saga/effects";

import * as types from "redux/actions/actionTypes";
import { signOut } from "redux/actions/auth";
import actions from "redux/actions/pipelines";

import { apiCall } from "utilities/api-helper";
import { createErrorNotification } from "utilities/notifications";

function* fetchPipeline() {
  yield takeLatest(
    types.PIPELINE_ONE + types.REQUEST_POSTFIX,
    function* fetchSaga({ urlId }) {
      try {
        const state = yield select((state) => state);
        const response = yield call(
          apiCall,
          {
            method: "GET",
            url: `/projects/${state?.auth?.project}/pipelines/${urlId}`,
          },
          state?.auth?.token
        );
        yield put(actions.getPipelineSuccess(response.data, urlId));
      } catch (error) {
        yield put(createErrorNotification(error.msg || error.message));
        yield put(actions.getPipelineFailure(error.msg, urlId));
        yield put(signOut());
      }
    }
  );
}

export default function* pipelines() {
  const sagas = [fetchPipeline];

  yield all(sagas.map((saga) => call(saga)));
}
