export const statusValues = [
  "available",
  "available_building",
  "available_building_failed",
  "pending",
  "pending_verification",
  "building",
  "building_failed",
  "deploying",
  "deploying_failed",
  "initialised",
  "failed",
  "failed_verification",
  "running",
  "none",
  "disabled",
  "",
  "success",
  "completed",
  "processing",
  "queued",
  "validating",
  "unavailable",
  "uploading",
  "cancelled",
  "cancelled_pending",
  "revert_icon_color",
  "scanning",
  "confirmation",
  "confirmation_pending",
];

export const formatStatusLabel = (label = " ") => {
  if (label === "available_building_failed") {
    label = "available";
  } else if (label === "available_building") {
    label = "building";
  } else if (label === "cancelled_pending") {
    label = "cancelling";
  } else if (label === "confirmation") {
    label = "waiting for confirmation";
  }
  return label.replace(/_/gi, " ");
};
