/**
 * Convenience method for downloading (smaller) files; first create a request, then pass off the response to this method
 * to trigger a file download. Loads the entire file in memory, but the status of the request is monitored by redux
 */
export const downloadFileBrowser = (response) => {
  var filename = response.headers["content-disposition"]
    .split("filename=")
    .pop()
    .replace(/"/g, "")
    .trim();

  // Create download in browser
  window.URL = window.URL || window.webkitURL;
  let url = window.URL.createObjectURL(response.data);

  let a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display:none";
  a.href = url;
  a.download = decodeURIComponent(filename);
  a.click();

  window.URL.revokeObjectURL(url);
  a.remove();
};

/**
 * Convenience method for downloading larger files. Creates a url for the file download and clicks it to trigger a browser
 * download. Streams files directly to disk, but if the request fails this is not reflected in the redux state.
 * @param url Url for file download. *Must* contain an authtoken query parameter.
 */
export const downloadFileStreaming = (url) => {
  let a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  a.href = url;
  a.click();
  a.parentNode.removeChild(a);
};

/**
 * Blob download is slightly different with the current setting. `download` and `target` property can ensure
 * that the user experience is the same as during normal file download
 * (file is downloaded instead of opened on a new tab as an image)
 */
export const downloadBlobStreaming = (url) => {
  let a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  a.href = url;
  a.download = "file.png"
  a.target = "_blank"
  a.click();
  a.parentNode.removeChild(a);
};
