import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { enqueueSnackbar } from "notistack";
import Close from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { closeSnackbar as onClose } from "notistack";
import { removeSnackbar } from "redux/actions/app";

const Notifier = ({
  notifications,
  removeSnackbar,
  closeSnackbar
}) => {
  const [displayed, setDisplayed] = useState([]);

  const storeDisplayed = (id) => {
    setDisplayed((prevDisplayed) => [...prevDisplayed, id]);
  };

  const removeDisplayed = (id) => {
    setDisplayed((prevDisplayed) => prevDisplayed.filter((key) => id !== key));
  };

  useEffect(() => {
    notifications.forEach(
      ({ key, message, options = {}, dismissed }) => {
        if (dismissed) {
          closeSnackbar(key);
          return;
        }
        // Do nothing if snackbar is already displayed
        if (displayed.includes(key)) {
          return;
        }
        // Display snackbar using notistack
        enqueueSnackbar(message, {
          key,
          ...options,
          variant: options.variant,
          action: (key) => (
            <IconButton
              onClick={() => onClose(key)}
              size="small"
              color="inherit"
            >
              <Close fontSize="small" />
            </IconButton>
          ),
          onClose: (event, reason, key) => {
            if (options.onClose) {
              options.onClose(event, reason, key);
            }
          },
          onExited: (event, key) => {
            removeSnackbar(key);
            removeDisplayed(key);
          },
        });
        // Keep track of snackbars that we've displayed
        storeDisplayed(key);
      }
    );
  }, [notifications, displayed, removeSnackbar, closeSnackbar]);

  return null;
};

Notifier.propTypes = {
  notifications: PropTypes.array.isRequired,
  removeSnackbar: PropTypes.func.isRequired,
};

const mapStateToProps = ({ app: { notifications } }) => ({
  notifications,
});

const mapDispatchToProps = {
  removeSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifier);
