import { fork, all } from "redux-saga/effects";

import blobs from "./blobs";
import deployments from "./deployments";
import pipelines from "./pipelines";

export default function* startup() {
  // These can run before the user chooses a project
  const sagas = [blobs, deployments, pipelines];

  yield all(sagas.map((saga) => fork(saga)));
}
