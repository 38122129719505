import { put, call, select, all, takeEvery, fork } from "redux-saga/effects";
import * as types from "redux/actions/actionTypes";

import actions from "redux/actions/blobs";
import { createErrorNotification } from "utilities/notifications";
import { apiCall } from "utilities/api-helper";
import { createLoader, progressWatcher } from "utilities/progress-helper";
import { downloadFileBrowser } from "utilities/download-helper";
import { TIME_OUT_FILES } from "utilities/constants";

function* getBlobs() {
  yield takeEvery(
    types.BLOB_ALL + types.REQUEST_POSTFIX,
    function* fetchSaga() {
      try {
        const state = yield select((state) => state);
        const response = yield call(
          apiCall,
          {
            method: "GET",
            url: `/projects/${state?.auth?.project}/blobs`,
          },
          state?.auth?.token
        );
        yield put(actions.getBlobsSuccess(response.data));
      } catch (error) {
        if (error.response?.status === 403) {
          yield put(actions.getBlobsSuccess({}));
        } else {
          yield put(createErrorNotification(error.msg || error.message));
          yield put(actions.getBlobsFailure(error.msg));
        }
      }
    }
  );
}

function* createBlob() {
  yield takeEvery(
    types.BLOB_CREATE + types.REQUEST_POSTFIX,
    function* uploadSaga({ requestBody, trackName }) {
      try {
        const state = yield select((state) => state);
        const [promise, chan] = yield call(
          createLoader,
          {
            method: "POST",
            url: `/projects/${state?.auth?.project}/blobs`,
            data: requestBody,
            timeout: TIME_OUT_FILES, // set file upload timeout to 1h to handle large files
          },
          "Upload",
          null,
          state?.auth?.token
        );
        yield fork(
          progressWatcher,
          actions.createBlobProgress,
          chan,
          null,
          trackName
        );
        const response = yield call(() => promise);
        yield put(
          actions.createBlobSuccess(response.data, null, null, trackName)
        );
      } catch (error) {
        yield put(createErrorNotification(error.msg || error.message));
        yield put(actions.createBlobFailure(error, null, null, trackName));
      }
    }
  );
}

function* getBlob() {
  yield takeEvery(
    types.BLOB_ONE + types.REQUEST_POSTFIX,
    function* downloadSaga({ urlId, requestBody }) {
      try {
        const state = yield select((state) => state);
        const [promise, chan] = yield call(
          createLoader,
          {
            method: "GET",
            url: `/projects/${state?.auth?.project}/blobs/${urlId}`,
            timeout: TIME_OUT_FILES,
            responseType: "blob",
          },
          "Download",
          null,
          state?.auth?.token
        );
        yield fork(progressWatcher, actions.getBlobProgress, chan, urlId);
        const response = yield call(() => promise);

        if (requestBody?.preview) {
          const filename = response.headers["content-disposition"]
            .split("filename=")
            .pop()
            .replace(/"/g, "")
            .trim();
          const objectUrl = URL.createObjectURL(response.data);
          yield put(
            actions.getBlobSuccess({ filename, objectUrl }, urlId, null, urlId)
          );
        } else {
          yield put(actions.getBlobSuccess(null, urlId, null, urlId));
          downloadFileBrowser(response);
          yield put(actions.getBlobReset(urlId));
        }
      } catch (error) {
        yield put(createErrorNotification(error.msg || error.message));
        yield put(actions.getBlobFailure(error, urlId));
      }
    }
  );
}

export default function* blobs() {
  const sagas = [getBlobs, getBlob, createBlob];

  yield all(sagas.map((saga) => call(saga)));
}
