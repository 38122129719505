import React from "react";

import { CircularProgress, Box } from "@mui/material";

const Loader = (props) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="100%"
    {...props}
  >
    <CircularProgress m={2} color="secondary" />
  </Box>
);

export default Loader;
