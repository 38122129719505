import { useParams } from "react-router-dom";
import { useDeleteFileMutation } from "redux/reducers/filesApi";
import { ENV_DEFAULT_BUCKET } from "utilities/constants";
import { getBucketName } from "utilities/input-parser";

const useDeleteFile = (token, request) => {
  const { projectName } = useParams();
  const [onFileDelete] = useDeleteFileMutation();
  
  const deleteFile = async (file, fieldName) => {
    const bucketName = getBucketName(fieldName, request);
    await onFileDelete({
      projectName,
      bucketName,
      fileName: file?.name?.replace(ENV_DEFAULT_BUCKET, ''),
      token,
    })
  }

  return { deleteFile }
}

export default useDeleteFile;