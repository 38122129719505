import axios from "axios";

import { parseErrorResponse } from "utilities/api-helper";
import { ENV_HOST, PROXY_HOST } from "utilities/constants";

import env from "./EnvironmentManager";

const TIMEOUT = 30000; // ms
const METHODS = ["GET", "POST", "PUT", "HEAD", "PATCH", "DELETE"];

// fallback for microsoft edge
export const API_URL = env.get(ENV_HOST) || "https://api.ubiops.com/v2.1";
export const PROXY_URL = env.get(PROXY_HOST) || "https://files-proxy.ubiops.com";

export default async function request(args, token) {
  // Defaults
  args = Object.assign(
    {
      method: "GET",
      baseURL: API_URL,
      timeout: TIMEOUT,
      headers: {
        Authorization: token,
      },
    },
    args
  );

  // Validate Args.

  if (!METHODS.includes(args.method)) {
    throw new Error("Invalid Request Method");
  }

  if (!args.baseURL) {
    throw new Error("Invalid Request Baseurl");
  }

  const axiosInstance = axios.create();

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error.response) {
        if (
          error.request.responseType === "blob" &&
          error.response.data instanceof Blob &&
          error.response.data.type &&
          error.response.data.type.toLowerCase().indexOf("json") !== -1
        ) {
          return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
              error.response.data = JSON.parse(reader.result);
              resolve(Promise.reject(error));
            };

            reader.onerror = () => {
              reject(error);
            };

            reader.readAsText(error.response.data);
          });
        }
      } else {
        // Catches javascript error e.g. network error
        parseErrorResponse(error);
      }

      return Promise.reject(error);
    }
  );

  // Build Request
  return await axiosInstance.request(args);
}
