import { handleActions } from "redux-actions";
import _ from "lodash";

import * as types from "redux/actions/actionTypes";

// Initial State
const initialState = {
  blobs: {},
  currentBlobs: {},
  uploadProgress: {},
  downloadProgress: {},
  downloadPreviews: {},
};

// Reducer
const blobs = handleActions(
  {
    [types.BLOB_ALL + types.SUCCESS_POSTFIX]: (state, action) => ({
      ...state,
      blobs: _.keyBy(action.data, "id"),
    }),
    [types.BLOB_ALL + types.RESET_POSTFIX]: (state, action) => ({
      ...state,
      blobs: {},
    }),
    [types.BLOB_CREATE + types.SUCCESS_POSTFIX]: (state, action) => ({
      ...state,
      currentBlobs: {
        ...state.currentBlobs,
        [action.trackName]: action.data.id,
      },
    }),
    [types.BLOB_CREATE + types.PROGRESS_POSTFIX]: (state, action) => ({
      ...state,
      uploadProgress: {
        ...state.uploadProgress,
        [action.trackName]: action.data,
      },
    }),
    [types.BLOB_CREATE + types.RESET_POSTFIX]: (state, action) => ({
      ...state,
      currentBlobs: _.omit(state.currentBlobs, action.trackName),
    }),
    [types.BLOB_ONE + types.PROGRESS_POSTFIX]: (state, action) => ({
      ...state,
      downloadProgress: {
        ...state.downloadProgress,
        [action.urlId]: action.data,
      },
    }),
    [types.BLOB_ONE + types.SUCCESS_POSTFIX]: (state, action) => ({
      ...state,
      downloadPreviews: {
        ...state.downloadPreviews,
        [action.urlId]: action.data,
      },
    }),
    [types.BLOB_ONE + types.RESET_POSTFIX]: (state, action) => {
      if (state.downloadPreviews?.[action.urlId]?.objectUrl) {
        URL.revokeObjectURL(state.downloadPreviews?.[action.urlId]?.objectUrl);
      }
      return {
        ...state,
        downloadPreviews: _.omit(state.downloadPreviews, action.urlId),
        downloadProgress: _.omit(state.downloadProgress, action.urlId),
      };
    },
  },
  initialState
);

export default blobs;
