import { connectRouter } from "connected-react-router";

import { combineReducers } from "redux";
import { SIGN_OUT } from "redux/actions/actionTypes";

import app from "./app";
import auth from "./auth";
import blobs from "./blobs";
import deployments from "./deployments";
import pipelines from "./pipelines";

import { status, statusWithIds } from "./status";
import { filesApi } from "./filesApi";

const appReducer = (history) =>
  combineReducers({
    app,
    auth,
    blobs,
    deployments,
    pipelines,
    status,
    statusWithIds,
    [filesApi.reducerPath]: filesApi.reducer,
    router: connectRouter(history),
  });

const rootReducer = (history) => (state, action) => {
  if (action.type === SIGN_OUT) {
    state = undefined;
  }
  return appReducer(history)(state, action);
};

export default rootReducer;
