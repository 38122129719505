import { handleActions } from "redux-actions";
import * as types from "redux/actions/actionTypes";
import { orderFieldsByName } from "utilities/utils";

// Initial State
const initialState = {
  pipeline: null,
};

// Reducer
const pipelines = handleActions(
  {
    [types.PIPELINE_ONE + types.SUCCESS_POSTFIX]: (state, action) => ({
      ...state,
      pipeline: {
        ...action.data,
        input_fields: orderFieldsByName(action.data.input_fields),
        output_fields: orderFieldsByName(action.data.output_fields),
      },
    }),
    [types.PIPELINE_ONE + types.RESET_POSTFIX]: (state) => ({
      ...state,
      pipeline: null,
    }),
  },
  initialState
);

export default pipelines;
