export const LINK_COPYRIGHT = "https://ubiops.com/";
export const LINK_PRIVACY = "https://ubiops.com/ubiops-privacy-policy/";
export const LINK_SAAS = "https://ubiops.com/ubiops-saas-terms-and-conditions/";
export const LINK_SAAS_FREE =
  "https://ubiops.com/saas-services-tc-ubiops-free/";

export const LINK_DOCS = "https://ubiops.com/docs/";
export const LINK_SUPPORT =
  "https://ubiops.atlassian.net/servicedesk/customer/portals";
export const LINK_CREATE_BUG =
  "https://ubiops.atlassian.net/servicedesk/customer/portal/2/group/3/create/14";
export const LINK_SUGGEST_IMPROVEMENT =
  "https://ubiops.atlassian.net/servicedesk/customer/portal/2/group/3/create/16";
export const LINK_QUICKSTART = "https://ubiops.com/docs/tutorials/quickstart/";

export const LINK_CLI = "https://github.com/UbiOps/command-line-interface";
export const LINK_PYTHON_CLIENT_LIBRARY =
  "https://github.com/UbiOps/client-library-python";

export const LINK_PRICING = "https://ubiops.com/pricing-and-plans/";
export const LINK_SLACK_INVITE =
  "https://join.slack.com/t/ubiops-community/shared_invite/zt-np02blts-5xyFK0azBOuhJzdRSYwM_w";

export const LINK_COOKBOOK = "https://ubiops.com/docs/ubiops_cookbook/";
export const LINK_DEPLOYMENT_PACKAGE =
  "https://ubiops.com/docs/deployments/deployment-package/deployment-structure/";

export const HELP_LINKS = [
  {
    label: "Documentation",
    url: LINK_DOCS,
  },
  {
    label: "Contact support",
    url: LINK_SUPPORT,
  },
  {
    label: "Report a bug",
    url: LINK_CREATE_BUG,
  },
  {
    label: "Suggest improvement",
    url: LINK_SUGGEST_IMPROVEMENT,
  },
];
