import { put, take } from "redux-saga/effects";
import { push } from "connected-react-router";

import * as types from "redux/actions/actionTypes";

import { createErrorNotification } from "utilities/notifications";

export function* signOut() {
  while (true) {
    yield take(types.SIGN_OUT);
    yield put(push("/"));
  }
}

export function* invalidToken() {
  yield put(createErrorNotification("The activation token is invalid"));
}
