import {
  REQUEST_POSTFIX,
  SUCCESS_POSTFIX,
  FAILURE_POSTFIX,
  RESET_POSTFIX,
  PROGRESS_POSTFIX,
} from "redux/actions/actionTypes";

/**
 * Generates request, success and failure actions (optionally a reset action as well)
 * @param action The action name: generated actions will all be this name with a postfix
 * @param createReset boolean, creates a reset action if true
 * @param trackId boolean, if true concurrent requests will be kept track of by ID
 * @returns An object with action names as keys and actions as values. Can be deconstructed & combined or just exported
 */
export const generateActions = (
  action,
  createReset = false,
  trackId = false,
  createProgress = false
) => {
  const actions = {
    [action]: (urlId, requestBody, trackName) => ({
      type: action + REQUEST_POSTFIX,
      urlId,
      requestBody,
      trackId,
      trackName,
    }),
    [action + "Success"]: (data, urlId, requestBody, trackName) => ({
      type: action + SUCCESS_POSTFIX,
      data,
      urlId,
      requestBody,
      trackId,
      trackName,
    }),
    [action + "Failure"]: (error, urlId, requestBody, trackName) => ({
      type: action + FAILURE_POSTFIX,
      error,
      urlId,
      requestBody,
      trackId,
      trackName,
    }),
  };
  if (createReset) {
    actions[action + "Reset"] = (urlId, trackName) => ({
      type: action + RESET_POSTFIX,
      urlId,
      trackId,
      trackName,
    });
  }
  if (createProgress) {
    actions[action + "Progress"] = (data, urlId, requestBody, trackName) => ({
      type: action + PROGRESS_POSTFIX,
      data,
      urlId,
      requestBody,
      trackId,
      trackName,
    });
  }
  return actions;
};
