import React from "react";
import { useParams } from "react-router-dom";

import "./Home.scss";

import { Card, CardContent, Grid, Typography } from "@mui/material";

import { PageTitle } from "components/molecules";
import { PageContainer, TokenForm, MainLayout } from "components/organisms";

const Home = () => {
  const {
    projectName,
    pipelineName,
    deploymentName,
    versionName,
  } = useParams();

  return (
    <MainLayout>
      <PageContainer>
        <PageTitle title="Authentication" />
        <Grid container direction="column" alignItems="center">
          <Grid container direction="column">
            <Grid item sm={12} xs={12}>
              <div className="home__section">
                <Typography className="home__welcome" color="primary">
                  {!deploymentName && !pipelineName ? (
                    <>
                      Welcome to the UbiOps deployment and pipeline interface.
                      Fill in the form fields below to set up a connection to
                      the deployment/pipeline you wish to use.
                    </>
                  ) : (
                    <>
                      Welcome to the UbiOps{" "}
                      {!!deploymentName ? " deployment" : " pipeline"}{" "}
                      interface. Fill in the token to set up a connection to the
                      {!!deploymentName ? " deployment" : " pipeline"}.
                    </>
                  )}
                </Typography>
                <Card variant="outlined">
                  <CardContent>
                    <TokenForm
                      project={projectName}
                      deployment={deploymentName}
                      pipeline={pipelineName}
                      version={versionName}
                    />
                  </CardContent>
                </Card>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </PageContainer>
    </MainLayout>
  );
};

export default Home;
