import {
  primary,
  secondary,
  white,
  error,
  background,
  black,
  success,
  pending,
  blue,
} from "./colors";
import { rgba } from "polished";

const appTheme = {
  name: "UbiOps",
  palette: {
    primary: {
      main: primary,
      contrastText: white,
    },
    secondary: {
      main: secondary,
      contrastText: white,
    },
    error: {
      main: error,
      contrastText: white,
    },
    warning: {
      main: pending,
      contrastText: black,
    },
    info: {
      main: blue,
      contrastText: white,
    },
    success: {
      main: success,
      contrastText: white,
    },
    text: {
      primary: rgba(primary, 0.87),
      secondary: rgba(primary, 0.54),
      disabled: rgba(primary, 0.38),
      hint: rgba(primary, 0.38),
    },
    action: {
      active: rgba(primary, 0.54),
      hover: rgba(primary, 0.08),
      selected: rgba(primary, 0.14),
      disabled: rgba(primary, 0.26),
      disabledBackground: rgba(primary, 0.12),
    },
  },
  header: {
    color: white,
    background: primary,
    search: {
      color: white,
    },
    indicator: {
      background: error,
    },
  },
  body: {
    background: background,
  },
};

export default appTheme;
