import { call, put } from "@redux-saga/core/effects";
import _ from "lodash";

import request from "services/request";
import { signOut } from "redux/actions/auth";
import { invalidToken } from "redux/sagas/auth";

import { store } from "App";
import { createErrorNotification } from "utilities/notifications";

export function api(apiRequest, token) {
  try {
    return request(apiRequest, token);
  } catch (error) {
    if (error.response?.status === 401) {
      store.dispatch(signOut());
      store.dispatch(
        createErrorNotification("Your token is invalid", {
          key: "login-session",
        })
      );
    }
    parseErrorResponse(error);
    throw error;
  }
}

export function* apiCall(apiRequest, token) {
  try {
    return yield call(request, apiRequest, token);
  } catch (error) {
    if (error.response?.status === 401) {
      if (error.response.data?.error === "Invalid token") {
        yield call(invalidToken);
      } else {
        yield put(signOut());
      }
    }
    parseErrorResponse(error);
    throw error;
  }
}

export const parseErrorResponse = (error) => {
  // Catches javascript error e.g. network error
  if (_.has(error, "message")) {
    error.msg = "Could not reach UbiOps due to network issues";
  }
  if (_.has(error, "response.data.error")) {
    error.msg =
      error.response.data.error === "timeout of 30000ms exceeded"
        ? "Could not reach UbiOps due to network issues"
        : error.response.data.error;
  }
  if (_.has(error, "response.status")) {
    error.status = error.response.status;
  }
};
