import React from "react";
import { LINK_COPYRIGHT } from "utilities/documentation-links";
import "./Copyright.scss";

const Copyright = () => (
  <a
    className="copyright"
    href={LINK_COPYRIGHT}
    target="_blank"
    rel="noopener noreferrer"
  >
    © {new Date().getFullYear()} UbiOps
  </a>
);

export default Copyright;
