import React from "react";
import PropTypes from "prop-types";
import { TextField as MuiTextField, Grid, Box } from "@mui/material";
import { useFormContext } from "react-hook-form";
import _ from "lodash";

import { InfoTooltip } from "components/atoms";

const TextField = ({
  name,
  rules = {},
  label,
  withError = true,
  tooltip,
  ...props
}) => {
  const { errors, register } = useFormContext();
  const error = _.get(errors, name);

  return (
    <Grid container>
      <Grid item xs>
        <MuiTextField
          error={!!error}
          helperText={withError ? error?.message ?? "" : ""}
          name={name}
          label={label}
          margin="dense"
          variant="outlined"
          inputRef={register(rules)}
          fullWidth={true}
          {...props}
        />
      </Grid>
      {tooltip && (
        <Grid item xs={1} component={Box} paddingTop={2}>
          <InfoTooltip>{tooltip}</InfoTooltip>
        </Grid>
      )}
    </Grid>
  );
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  register: PropTypes.object,
  label: PropTypes.string,
  withError: PropTypes.bool,
  tooltip: PropTypes.string,
};

export default TextField;
