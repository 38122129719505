import * as types from "redux/actions/actionTypes";
import { generateActions } from "redux/actions/utils";

const actions = {
  ...generateActions(types.PIPELINE_ONE, true),
};

export default actions;

export const { getPipeline, getPipelineReset } = actions;
