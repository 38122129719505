import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { Home, Request } from "components/pages";
import { RootUrlContext } from "containers/contexts";

const Routes = (match) => {
  return (
    <RootUrlContext.Provider value={match.url}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route
          exact
          path="/projects/:projectName/deployments/:deploymentName"
          component={Request}
        />
        <Route
          exact
          path="/projects/:projectName/deployments/:deploymentName/versions/:versionName"
          component={Request}
        />
        <Route
          exact
          path="/projects/:projectName/pipelines/:pipelineName"
          component={Request}
        />
        <Route
          exact
          path="/projects/:projectName/pipelines/:pipelineName/versions/:versionName"
          component={Request}
        />
        <Redirect to="/" />
      </Switch>
    </RootUrlContext.Provider>
  );
};

export default Routes;
