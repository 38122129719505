import React from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import ArrowDown from "@mui/icons-material/KeyboardArrowDownRounded";

import { ReactComponent as BrandLogoHorizontal } from "assets/images/logo-horizontal.svg";
import { ReactComponent as BrandLogoVertical } from "assets/images/logo-vertical-white.svg";
import { AppBar, Grid, Hidden } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import { DropdownMenu } from "components/molecules";

import { HELP_LINKS } from "utilities/documentation-links";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.modal + 1,
    height: "44px",
    padding: "0 1em",
  },
}));

const Header = () => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" elevation={0} className={classes.appBar}>
      <Grid
        container
        alignItems="center"
        spacing={1}
        className="header__container"
        wrap="nowrap"
      >
        <Grid item component={Link} to="/" className="header__home-link">
          <Hidden only="xs">
            <BrandLogoHorizontal className="header__logo header__logo--horizontal" />
          </Hidden>
          <Hidden smUp>
            <BrandLogoVertical className="header__logo" />
          </Hidden>
        </Grid>
        <Grid item xs />
        <Grid item sx={{
          paddingBottom: "8px"
        }}>
          <DropdownMenu
            icon={
              <>
                <HelpIcon fontSize="small" />
                <ArrowDown fontSize="small" />
              </>
            }
            items={HELP_LINKS}
            className="header__icon-button"
            tooltip="Help"
          />
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default Header;
