import React from "react";
import "./PageTitle.scss";
import PropTypes from "prop-types";
import {
  Typography,
  Grid,
  Button,
  Box,
  useMediaQuery,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import { Divider } from "components/atoms";

const PageTitle = ({
  title,
  pageTitle,
  children,
  actions = [],
  direction = "row",
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  return (
    <div className="page-title">
      <Helmet>
        <title>UbiOps Requests</title>
      </Helmet>
      <Grid
        container
        alignItems="center"
        className="page-title__content"
        spacing={2}
      >
        <Grid
          item
          component={Typography}
          variant="h1"
          display="inline"
          className="page-title__content--title"
        >
          {pageTitle ?? title}
        </Grid>
        {!!children && direction === "row" && (
          <Grid item xs>
            {children}
          </Grid>
        )}
        {!!actions.length && (
          <Grid item xs container spacing={1} alignItems="center" wrap="nowrap">
            {actions.map(
              (
                {
                  color = "secondary",
                  label,
                  icon,
                  link,
                  onClick,
                  disabled,
                  alignRight = false,
                  hidden = false,
                },
                key
              ) =>
                !hidden && (
                  <Grid
                    item
                    key={key}
                    className={[
                      "page-title__item",
                      alignRight && "page-title__item--end",
                    ]
                      .filter((x) => x)
                      .join(" ")}
                  >
                    <Button
                      size={matches ? "small" : "medium"}
                      color={color}
                      startIcon={icon}
                      component={link ? Link : "button"}
                      to={link}
                      onClick={onClick}
                      disabled={disabled}
                    >
                      {label}
                    </Button>
                  </Grid>
                )
            )}
          </Grid>
        )}
      </Grid>
      {!!children && direction === "column" && <Box mt={2}>{children}</Box>}
      <Divider my={3} />
    </div>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  pageTitle: PropTypes.element,
  children: PropTypes.node,
  actions: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      link: PropTypes.string,
      onClick: PropTypes.func,
      icon: PropTypes.element,
      color: PropTypes.string,
      disabled: PropTypes.bool,
      alignRight: PropTypes.bool,
      hidden: PropTypes.bool,
    })
  ),
  direction: PropTypes.oneOf(["row", "column"]),
};

export default PageTitle;
