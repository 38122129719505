import { useParams } from "react-router-dom";
import { downloadBlobStreaming, downloadFileStreaming } from "utilities/download-helper";
import { useDownloadFileMutation, useDownloadBlobMutation } from "redux/reducers/filesApi";
import { ENV_FILE_PREFIX } from "utilities/constants";
import { getBucketNameFromFileName, isBlobFile } from "utilities/input-parser";

const useDownloadFile = (token) => {
  const { projectName } = useParams();
  const [download] = useDownloadFileMutation();
  const [downloadBlob] = useDownloadBlobMutation();
  
  const downloadFile = async (fileName) => {
    if (isBlobFile(fileName)) {
      const { data } = await downloadBlob({
        projectName,
        fileName,
        token,
      })
      data.url && downloadBlobStreaming(data.url)
    } else {
      const bucketName = getBucketNameFromFileName(fileName);
      const bucketPrefix = `${ENV_FILE_PREFIX}${bucketName}/`
      const { data } = await download({
        projectName,
        bucketName,
        fileName: fileName.replace(bucketPrefix, ''),
        token,
      })
      
      data.url && downloadFileStreaming(data.url)
    }
  }

  return { downloadFile }
}

export default useDownloadFile;