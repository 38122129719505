import * as types from "redux/actions/actionTypes";

// SIGN_IN
export const signIn = ({ token, project }) => {
  return {
    type: types.SIGN_IN,
    token,
    project,
  };
};

//LOGOUT
export const signOut = () => {
  return {
    type: types.SIGN_OUT,
  };
};
