export const stopPropagation = (event) => {
  event.stopPropagation();
};

export const getCookieSettings = (customOptions = {}) => ({
  path: "/",
  sameSite: "Lax",
  ...customOptions,
});

// Generate a version name according to the name of the latest version
// or return the name of the latest version
export const generateVersionName = (latestName) => {
  const versionNameNumber = latestName.match(/\d+$/);
  // If the end of the version name is not a number, this is not an array
  if (!Array.isArray(versionNameNumber)) {
    return latestName;
  }

  const prefix = latestName.replace(/\d+$/, "");
  // The first item in the array is the number at the end of the version name
  const number = parseInt(versionNameNumber[0]) + 1;
  return prefix + number;
};

export const formatLabel = (label) => {
  if (!label) {
    return "";
  }
  return label.charAt(0).toUpperCase() + label.slice(1).replace(/_/gi, " ");
};

export const createId = () => Math.random().toString(36).substr(2, 5);

export const isObjectHasData = (obj) =>
  Boolean(obj) && typeof obj === "object" && !!Object.keys(obj).length;

export const isArrayHasData = (arr) => Array.isArray(arr) && !!arr.length;

export const orderFieldsByName = (fields) => {
  if (!fields) return fields;
  return fields.sort((f1, f2) => {
    if (f1.name < f2.name) return -1;
    else if (f1.name > f2.name) return 1;
    else return 0;
  });
};

export const sumArrayOfNumbers = (num) => num.reduce((a, b) => a + b, 0);

export const numberFormatter = (num) => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(2) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(2) + "M"; // convert to M for number from > 1 million
  } else if (num < 900) {
    return num; // if value < 1000, nothing to do
  }
};
