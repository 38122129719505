export const REQUEST_POSTFIX = "_REQUEST";
export const SUCCESS_POSTFIX = "_SUCCESS";
export const FAILURE_POSTFIX = "_FAILURE";
export const RESET_POSTFIX = "_RESET";
export const PROGRESS_POSTFIX = "_PROGRESS";

// Notifications
export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";

// Auth
export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";

// Blobs
export const BLOB_ONE = "getBlob";
export const BLOB_ALL = "getBlobs";
export const BLOB_CREATE = "createBlob";

// Deployments
export const DEPLOYMENT_ONE = "getDeployment";

// Pipelines
export const PIPELINE_ONE = "getPipeline";
