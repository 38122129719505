import React from "react";
import PropTypes from "prop-types";
import "./MainLayout.scss";
import { CssBaseline } from "@mui/material";

import { Copyright, ScrollToTop } from "components/atoms";
import { Header, Footer } from "components/organisms";

const MainLayout = ({ children }) => {
  return (
    <div className="main-layout">
      <Header />
      <CssBaseline />
      <div className="main-layout__body">
        <ScrollToTop />
        {children}
        <Footer>
          <Copyright />
        </Footer>
      </div>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
