import React from "react";
import "./App.scss";
import "styles/global.scss";

import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from "@mui/material/styles";


import appTheme from "./styles/theme";
import Routes from "./routes/Routes";

import { SnackbarProvider } from "notistack";
import { AlertTriangle, Info, CheckCircle, AlertOctagon } from "react-feather";

import { Notifier } from "./components/organisms";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import configureStore, { history } from "redux/store";

export const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={appTheme}>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              iconVariant={{
                error: <AlertTriangle className="app__notification-icon" />,
                success: <CheckCircle className="app__notification-icon" />,
                warning: <AlertOctagon className="app__notification-icon" />,
                info: <Info className="app__notification-icon" />,
              }}
              style={{
                marginTop: "40px"
              }}
            >
              <Notifier />
              <Routes />
            </SnackbarProvider>
          </MuiThemeProvider>
        </StyledEngineProvider>
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
