import React from "react";
import { useFormContext } from "react-hook-form";

import { Grid, Typography } from "@mui/material";

import { Radio, TextField } from "components/atoms";

import validators from "utilities/validators";

const TokenForm = () => {
  const methods = useFormContext();
  const { register, watch } = methods;
  const type = watch("objectType", "deployment");

  return (
    <>
      <TextField
        name="project"
        label="Project"
        rules={{
          required: validators.required.message("project"),
        }}
        style={{ marginTop: "25px" }}
      />
      <Grid
        container
        spacing={1}
        alignItems="center"
        style={{ marginTop: "4px" }}
      >
        <Grid item>
          <Typography>Object type:</Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Radio
                value="deployment"
                register={register({
                  required: true,
                })}
                name="objectType"
                id="objectType_deployment"
                label="Deployment"
                defaultChecked
              />
            </Grid>
            <Grid item>
              <Radio
                value="pipeline"
                register={register({
                  required: true,
                })}
                name="objectType"
                id="objectType_pipeline"
                label="Pipeline"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {type === "pipeline" ? (
        <TextField
          name="pipeline"
          label="Pipeline"
          rules={{
            required: validators.required.message("pipeline"),
          }}
        />
      ) : (
        <TextField
          name="deployment"
          label="Deployment"
          rules={{
            required: validators.required.message("deployment"),
          }}
        />
      )}
      <TextField name="version" label="Version (optional)" />
    </>
  );
};

TokenForm.propTypes = {};

export default TokenForm;
